export const TIME_FORMAT = {
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  MESSAGE_TIME: 'YYYY/MM/DD HH:mm',
  HOUSE_MINUTE: (type: '-' | '/' | ':') => `HH${type}mm`,
  DATE: (type: '-' | '/') => `YYYY${type}MM${type}DD`,
};

export const QUERY_KEY = {
  LIST_PLAN: 'QUERY_PLAN',
  DETAIL_PLAN: 'QUERY_PLAN_DETAIL',
  LIST_PAYMENT_SETTING: 'LIST_PAYMENT_SETTING',
  DETAIL_PAYMENT_SETTING: 'DETAIL_PAYMENT_SETTING',
  LIST_GROUP_CHAT: 'LIST_GROUP_CHAT',
  COUNT_UNREAD_GROUP_CHAT: 'COUNT_UNREAD_GROUP_CHAT',
  DETAIL_GROUP_CHAT: 'DETAIL_GROUP_CHAT',
  COUNT_GROUP_BY_STATUS: 'COUNT_GROUP_BY_STATUS',
  DETAIL_USER: 'DETAIL_USER',
  ADMIN_ME: 'ADMIN_ME',
  COUNT_TAB_REQUEST: 'COUNT_TAB_REQUEST',
  LIST_NOTIFICATION: 'LIST_NOTIFICATION',
  LIST_STAFF: 'LIST_STAFF',
  LIST_RACK: 'LIST_RACK',
  ITEMS_MANAGEMENT: 'ITEMS_MANAGEMENT',
  ITEM_DETAIL: 'ITEM_DETAIL',
  ITEMS_COUNT: 'ITEMS_COUNT',
  QUERY_USER_BY_ID: 'QUERY_USER_BY_ID',
  STORAGE_ITEM: 'STORAGE_ITEM',
  LIST_REQUEST: 'LIST_REQUEST',
  LIST_GROUP_ITEM_MESSAGE: 'LIST_GROUP_ITEM_MESSAGE',
  LIST_GROUP_ITEM_MESSAGE_UNREAD: 'LIST_GROUP_ITEM_MESSAGE_UNREAD',
  LIST_GROUP_ITEM_MESSAGE_INQUIRY: 'LIST_GROUP_ITEM_MESSAGE_INQUIRY',
  LIST_GROUP_ITEM_MESSAGE_INTERNAL: 'LIST_GROUP_ITEM_MESSAGE_INTERNAL',
  LIST_NOTE: 'LIST_NOTE',
  LIST_SHIPMENT: 'LIST_SHIPMENT',
  ADMIN_SETTING: 'ADMIN_SETTING',
  USER_MANAGEMENT: 'USER_MANAGEMENT',

  PAYMENT_SETTING_STATUS: 'PAYMENT_SETTING_STATUS',
};

// export const ADMIN_KEY_SETTING = {
//   RECEPTION_KEY: 'reception-key',
//   TRANSPORT_KEY: 'transport-key',
// };

export const STATUS = {
  draft: 'draft',
  active: 'active',
  create: 'create',
};

export enum AdminRole {
  ADMIN = 'administrator',
  STAFF = 'staff',
  OPERATION = 'operation',
}

// custom status ONLY FE, BE undefined
export const INQUIRY_AND_INTERNAL_STATUS_FE = {
  NULL: null,
  OPEN: 'OPEN',
  CLOSED_UNREAD: 'CLOSED_UNREAD',
  CLOSED_READED: 'CLOSED_READED',
  NO_ACTION_OKE: 'NO_ACTION_OKE',
  NEW_MESSAGE: 'NEW_MESSAGE',
  CLOSED: 'CLOSED',
  WAITING_CUSTOMER: 'WAITING_CUSTOMER',
} as const;
// status get from BE
export const INQUIRY_AND_INTERNAL_STATUS_BE = {
  NULL: null,
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  NO_ACTION_OKE: 'NO_ACTION_OKE',
  NEW_MESSAGE: 'NEW_MESSAGE',
  WAITING_CUSTOMER: 'WAITING_CUSTOMER',
} as const;

export const statusChat = [
  INQUIRY_AND_INTERNAL_STATUS_BE.OPEN,
  INQUIRY_AND_INTERNAL_STATUS_BE.WAITING_CUSTOMER,
  INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED,
] as const;

export const STATUS_ITEM_MANAGEMENT = {
  REQUEST_CANCEL: 'CANCEL',
  NOT_DELIVERED: 'NOT_DELIVERED',
  DELIVERED: 'DELIVERED',
  WAITING_STORAGE: 'WAITING_STORAGE',
  WAITING_UPLOAD_IMAGE_VIDEO: 'WAITING_UPLOAD_IMAGE_VIDEO',
  IN_STORAGE: 'IN_STORAGE',
  SHIPPED_TO_BUYER: 'SHIPPED_TO_BUYER',
  SHIPPED_BACK: 'SHIPPED_BACK',
  DISPOSED: 'DISPOSED',
  IN_TRANSIT: 'IN_TRANSIT',
  NOT_SHIPPED: 'NOT_SHIPPED',
  SHIPPING_PREPARING: 'SHIPPING_PREPARING',
  SHIPPING_PREPARING_DONE: 'SHIPPING_PREPARING_DONE',
  NEW_DISPOSAL_REQUEST: 'NEW_DISPOSAL_REQUEST',
  PRICE_INPUTED: 'PRICE_INPUTED',
  CANCELED: 'CANCELED',
  SHIPPED: 'SHIPPED',
  REQUESTED: 'REQUESTED',
  TAKE_BY_OPERATION: 'TAKE_BY_OPERATION',
  UPLOAD_BY_STAFF: 'UPLOAD_BY_STAFF',
  SHIP_TO_BUYER_DELIVERED: 'SHIP_TO_BUYER_DELIVERED',
  SHIP_TO_BUYER_SHIPPED: 'SHIP_TO_BUYER_SHIPPED',
  IN_STORAGE_NOT_REFUNDED: 'IN_STORAGE_NOT_REFUNDED',
  INSTORAGE_REFUNDED: 'INSTORAGE_REFUNDED',
  SHIP_TO_BUYER_OPEN: 'SHIP_TO_BUYER_OPEN',
  SHIP_TO_BUYER_PREPARING_DONE: 'SHIP_TO_BUYER_PREPARING_DONE',
  DIPOSAL_OPEN: 'DIPOSAL_OPEN',
  DISPOSAL_WAIT: 'DISPOSAL_WAIT',
  DISPOSAL_DONE: 'DIPSAL_DONE',
  STORAGE_REQUEST_CANCELED: 'STORAGE_REQUEST_CANCELED',
  TEMPORARY_CANCELED: 'TEMPORARY_CANCELED',
};

export enum TAKE_PHOTO_REQUEST_STATUS {
  REQUESTED = 'REQUESTED',
  TAKE_BY_OPERATION = 'TAKE_BY_OPERATION',
  UPLOAD_BY_STAFF = 'UPLOAD_BY_STAFF',
}

export enum RETURN_STATUS_ENUM {
  CLOSED = 'CLOSED',
  ESCALATED = 'ESCALATED',
  ITEM_DELIVERED = 'ITEM_DELIVERED',
  ITEM_SHIPPED = 'ITEM_SHIPPED',
  LESS_THAN_A_FULL_REFUND_ISSUED = 'LESS_THAN_A_FULL_REFUND_ISSUED',
  PARTIAL_REFUND_DECLINED = 'PARTIAL_REFUND_DECLINED',
  PARTIAL_REFUND_FAILED = 'PARTIAL_REFUND_FAILED',
  PARTIAL_REFUND_INITIATED = 'PARTIAL_REFUND_INITIATED',
  PARTIAL_REFUND_REQUESTED = 'PARTIAL_REFUND_REQUESTED',
  READY_FOR_SHIPPING = 'READY_FOR_SHIPPING',
  REPLACED = 'REPLACED',
  REPLACEMENT_CLOSED = 'REPLACEMENT_CLOSED',
  REPLACEMENT_DELIVERED = 'REPLACEMENT_DELIVERED',
  REPLACEMENT_LABEL_REQUESTED = 'REPLACEMENT_LABEL_REQUESTED',
  REPLACEMENT_REQUESTED = 'REPLACEMENT_REQUESTED',
  REPLACEMENT_SHIPPED = 'REPLACEMENT_SHIPPED',
  REPLACEMENT_STARTED = 'REPLACEMENT_STARTED',
  REPLACEMENT_WAITING_FOR_RMA = 'REPLACEMENT_WAITING_FOR_RMA',
  RETURN_LABEL_REQUESTED = 'RETURN_LABEL_REQUESTED',
  RETURN_REJECTED = 'RETURN_REJECTED',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
  RETURN_REQUESTED_TIMEOUT = 'RETURN_REQUESTED_TIMEOUT',
  UNKNOWN = 'UNKNOWN',
  WAITING_FOR_RETURN_LABEL = 'WAITING_FOR_RETURN_LABEL',
  WAITING_FOR_RMA = 'WAITING_FOR_RMA',
}

export enum REQUEST_ACTION_ITEM_DETAIL {
  CREATE_DISPOSAL_REQUEST = 'CREATE_DISPOSAL_REQUEST',
  CANCEL_STORAGE_REQUEST = 'CANCEL_STORAGE_REQUEST',
  CANCEL_DISPOSAL_REQUEST = 'CANCEL_DISPOSAL_REQUEST',
  CANCEL_SHIP_TO_BUYER_REQUEST = 'CANCEL_SHIP_TO_BUYER_REQUEST',
  CANCEL_SHIP_BACK_REQUEST = 'CANCEL_SHIP_BACK_REQUEST',
}
