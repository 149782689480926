import SvgIconDowload from '../@svg/SvgDowload';
import './index.scss';
import { Button, ButtonProps } from 'antd';

const DowloadButton = (props: ButtonProps) => {
  return (
    <Button className={`download-button ${props.className}`} {...props}>
      <div className="m-l-4">
        <SvgIconDowload />
      </div>
      <span>エクスポート</span>
    </Button>
  );
};

export default DowloadButton;
