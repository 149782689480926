import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useRef } from 'react';
import { ChatItemContext } from '..';
import { itemInternalMessageApi } from '../../../../../apis';
import { ItemInquiryMessages, ItemInternalMessages, ItemMessageGroup } from '../../../../../apis/client-axios';
import { QUERY_KEY } from '../../../../../util/constants';
import InternalMessage from './message';

export interface InternalTabProps {
  listMessage: ItemInternalMessages[];
  setListMesage: React.Dispatch<React.SetStateAction<ItemInquiryMessages[] | [] | ItemInternalMessages[]>>;
  totalReponse: number;
  setTotalResponse: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  size: number;
  setSkipLoad: React.Dispatch<React.SetStateAction<boolean>>;
  skipLoad: boolean;
  setFileUploads: React.Dispatch<React.SetStateAction<{ privew: string; source: File }[] | undefined>>;
  fileUploads: { privew: string; source: File }[] | undefined;
  scrollToEnd: boolean;
  groupSelect: ItemMessageGroup | undefined;
}
export const InternalTab = () => {
  const {
    listMessage,
    setListMesage,
    totalReponse,
    setTotalResponse,
    page,
    setPage,
    size,
    skipLoad,
    setSkipLoad,
    fileUploads,
    setFileUploads,
    scrollToEnd,
    groupSelect,
  } = useContext(ChatItemContext);
  const dummy = useRef(null);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.LIST_GROUP_ITEM_MESSAGE_INTERNAL, page],
    queryFn: () =>
      itemInternalMessageApi.itemIntenalControllerGetAll(groupSelect?.id, page, size).then((res: any) => {
        let response: any = res;
        if (!!(res?.data as any)?.content?.length) {
          response.data.content = (res?.data as any)?.content.reverse();
        }
        return response;
      }),
    enabled: !!groupSelect?.id && groupSelect?.type == 1,
    staleTime: 1000,
  });

  useEffect(() => {
    if (data?.data?.content) {
      if (page == 1) {
        setListMesage(data?.data?.content as ItemInternalMessages[]);
      } else {
        if (!skipLoad) {
          const messages = [...(data?.data?.content as ItemInternalMessages[]), ...listMessage];
          setListMesage(messages);
          setSkipLoad(true);
        }
      }
      setTotalResponse(data?.data?.content?.length || 0);
    }
  }, [data]);

  useEffect(() => {
    if (dummy?.current && !isLoading && (page === 1 || scrollToEnd)) {
      document.getElementById('scrollMessage')?.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
    }
  }, [listMessage, dummy?.current, scrollToEnd]);

  // console.log(listMessage);

  return (
    <>
      {listMessage?.map((item: ItemInternalMessages) => {
        return <InternalMessage itemMessage={item} key={item?.id} />;
      })}
      <div className="p-t-12" ref={dummy} id="scrollMessage" style={{ height: '1px' }}></div>
    </>
  );
};
