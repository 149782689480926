export const PrintIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M16.353 12H17C17.943 12 18.414 12 18.707 11.707C19 11.414 19 10.943 19 10V9C19 7.114 19 6.172 18.414 5.586C17.828 5 16.886 5 15 5H5C3.114 5 2.172 5 1.586 5.586C1 6.172 1 7.114 1 9V11C1 11.471 1 11.707 1.146 11.854C1.293 12 1.53 12 2 12H3.647"
      stroke="#514B4D"
      strokeWidth="1.5"
    />
    <path
      d="M16 5V3.88C16 2.872 16 2.368 15.804 1.983C15.6314 1.64411 15.3559 1.36861 15.017 1.196C14.632 1 14.128 1 13.12 1H6.88C5.872 1 5.368 1 4.983 1.196C4.64411 1.36861 4.36861 1.64411 4.196 1.983C4 2.368 4 2.872 4 3.88V5M4 18.306V10C4 9.057 4 8.586 4.293 8.293C4.586 8 5.057 8 6 8H14C14.943 8 15.414 8 15.707 8.293C16 8.586 16 9.057 16 10V18.306C16 18.623 16 18.781 15.896 18.856C15.792 18.931 15.642 18.881 15.342 18.781L13.158 18.053C13.08 18.027 13.041 18.013 13 18.013C12.96 18.013 12.92 18.027 12.842 18.053L10.158 18.947C10.08 18.973 10.041 18.987 10 18.987C9.96 18.987 9.92 18.973 9.842 18.947L7.158 18.053C7.08 18.027 7.041 18.013 7 18.013C6.96 18.013 6.92 18.027 6.842 18.053L4.658 18.781C4.358 18.881 4.208 18.931 4.104 18.856C4 18.781 4 18.623 4 18.306Z"
      stroke="#514B4D"
      strokeWidth="1.5"
    />
    <path d="M8 12H11M8 15H12.5" stroke="#514B4D" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
