export default function SvgTime() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1.9087C4.63587 1.9087 1.9087 4.63587 1.9087 8C1.9087 11.3641 4.63587 14.0913 8 14.0913C11.3641 14.0913 14.0913 11.3641 14.0913 8C14.0913 4.63587 11.3641 1.9087 8 1.9087ZM1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8Z"
        fill="#FD161A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2.86523C8.25093 2.86523 8.45435 3.06865 8.45435 3.31958V7.98423C8.45435 8.23516 8.25093 8.43858 8 8.43858C7.74907 8.43858 7.54565 8.23516 7.54565 7.98423V3.31958C7.54565 3.06865 7.74907 2.86523 8 2.86523Z"
        fill="#FD161A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.60634 7.75755C7.73179 7.54023 8.00966 7.46575 8.22698 7.5912L11.4226 9.43586C11.6399 9.56131 11.7144 9.83918 11.5889 10.0565C11.4635 10.2738 11.1856 10.3483 10.9683 10.2228L7.77269 8.37819C7.55537 8.25274 7.48089 7.97487 7.60634 7.75755Z"
        fill="#FD161A"
      />
    </svg>
  );
}
