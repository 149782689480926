import React from 'react';

export const SvgIconCheck = () => (
  <span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.125 13.125L9.375 18.375L19.875 7.125"
        stroke="#514B4D"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default SvgIconCheck;
