export const MoreSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M7.99984 2.5C7.2665 2.5 6.6665 3.1 6.6665 3.83333C6.6665 4.56667 7.2665 5.16667 7.99984 5.16667C8.73317 5.16667 9.33317 4.56667 9.33317 3.83333C9.33317 3.1 8.73317 2.5 7.99984 2.5ZM7.99984 11.8333C7.2665 11.8333 6.6665 12.4333 6.6665 13.1667C6.6665 13.9 7.2665 14.5 7.99984 14.5C8.73317 14.5 9.33317 13.9 9.33317 13.1667C9.33317 12.4333 8.73317 11.8333 7.99984 11.8333ZM7.99984 7.16667C7.2665 7.16667 6.6665 7.76667 6.6665 8.5C6.6665 9.23333 7.2665 9.83333 7.99984 9.83333C8.73317 9.83333 9.33317 9.23333 9.33317 8.5C9.33317 7.76667 8.73317 7.16667 7.99984 7.16667Z"
        fill="black"
      />
    </svg>
  );
};
