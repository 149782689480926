import * as _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isLength from 'validator/lib/isLength';
import emojiRegex from 'emoji-regex';

type Option = {
  message: string;
  min?: number;
  max?: number;
};

type validateType =
  | 'required'
  | 'email'
  | 'phone'
  | 'postCode'
  | 'password'
  | 'space'
  | 'number'
  | 'normal'
  | 'isLength'
  | 'loginId'
  | 'emoj'
  | 'nameFull'
  | 'rackName'
  | 'max100Characters';

type ValidatorOption = string | boolean | Option;
type Validators = { [key in validateType]?: ValidatorOption };

const regexEmoj = emojiRegex();
export const regexPass = /^[A-Za-z\d#$@!%&*?.]{8,16}$/;
export const regexPosCode = /^\d{6,}$/;
export const spaceRegex = /\s+/;
export const REGEX_PHONE_NUMBER =
  /^(0)[1-9]((\s|\s?\-\s?)?[0-9])([0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]?[0-9]$/;
export const regexImage = /\.(jpg|jpeg|jfif|png|svg)$/i;
export const regexNormal = /^[a-z1-9]+$/;
export const regexLoginId = /^[A-Za-z0-9_]+$/;
export const regexRackName = /^[A-Za-z0-9_-]+$/;
export const regexMax100Characters = /^.{1,101}$/;

const getMessage = (option: ValidatorOption): string => {
  return typeof option === 'object' ? option.message : (option as string);
};

const VALIDATOR: any = {
  required: (value: string | number | undefined | null, option: ValidatorOption) => {
    if (value === undefined || value === null || value.toString().trim() === '') {
      throw new Error(getMessage(option));
    }

    const type = typeof value;
    let isValid = true;
    if (type === 'string') {
      isValid = !!(value as string).trim();
    }

    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  password: (value: string, option: ValidatorOption) => {
    if (!value) {
      return;
    }

    const isValid = regexPass.test(value.trim());
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  space: (value: string, option: ValidatorOption) => {
    if (!value) {
      return;
    }
    if (spaceRegex.test(value)) {
      throw new Error(getMessage(option));
    }
  },

  email: (value: string, option: ValidatorOption) => {
    if (!value) {
      return;
    }
    const isValid = isEmail(value);
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  phone: (value: string, option: ValidatorOption) => {
    const phoneNumber = value?.replace(/[-\s]/g, '');
    if (!REGEX_PHONE_NUMBER.test(phoneNumber)) {
      throw new Error(getMessage(option));
    }
  },

  nameFull: (value: string, option: ValidatorOption) => {
    const isValid = !regexEmoj.test(value);
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  loginId: (value: string, option: ValidatorOption) => {
    if (!value) {
      return;
    }
    const isValid = regexLoginId.test(value);
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  postCode: (value: string, option: Option) => {
    if (!value) {
      return;
    }

    const isValid = regexPosCode.test(value);
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  number: (value: string, option: Option) => {
    if (!value) {
      return;
    }

    if (isNaN(+value)) {
      throw new Error(getMessage(option));
    }
  },

  normal: (value: string, option: Option) => {
    if (!regexNormal.test(value)) {
      throw new Error(getMessage(option));
    }
  },

  isLength: (value: string, option: Option) => {
    const isValid = isLength(value, option);
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  emoj: (value: string, option: ValidatorOption) => {
    const isValid = !regexEmoj.test(value);
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },

  rackName: (value: string, option: Option) => {
    if (!regexRackName.test(value)) {
      throw new Error(getMessage(option));
    }
  },

  max100Characters: (value: string, option: ValidatorOption) => {
    if (!value) {
      return;
    }
    const isValid = regexMax100Characters.test(value.trim());
    if (!isValid) {
      throw new Error(getMessage(option));
    }
  },
};

export const _validator = (validators: Validators) => {
  return async (rule: any, text: string) => {
    _.map(validators, (options, type) => {
      options && VALIDATOR[type] && VALIDATOR[type](text, options);
    });
  };
};
