import moment from 'moment';
import { STATUS_ITEM_MANAGEMENT } from './constants';
import axios from 'axios';
import momentUS from 'moment-timezone';

export const toCamelCase = (str) => {
  // Split the string into words
  let words = str.split(/[\s-_]+/);

  // Convert the first word to lowercase
  let camelCaseStr = words[0].toLowerCase();

  // Process each subsequent word
  for (let i = 1; i < words.length; i++) {
    let word = words[i];
    camelCaseStr += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  return camelCaseStr;
};

export const getLinkCancelationNavigate = (returnId: string) =>
  `https://www.ebay.com/rtn/Return/ReturnsDetail?returnId=${returnId}`;

export const getLinkUserEbayNavigate = (userEbayId: string, type: 'str' | 'usr') =>
  `https://www.ebay.com/${type}/${userEbayId}`;

export const getLinkItemEbayNavigate = (itemId: string) => `https://www.ebay.com/itm/${itemId}`;

//YYYY/MM/DD
export const formatDate = (time: Date | string) => (time ? moment(time).format('YYYY/MM/DD') : '');

export const AmericaTimezone = 'America/Los_Angeles';

export const formatDateUS = (time: Date | string) =>
  time ? momentUS(time).tz(AmericaTimezone).format('YYYY/MM/DD') : '';

//YYYY年MM月DD日
export const formatDateJP = (isoString: string) => {
  const formattedDate = moment(isoString).format('YYYY年MM月DD日');
  return formattedDate;
};
export const formatCurrency = (number: number, minimumFractionDigits = 0, maximumFractionDigits = 2) =>
  new Intl.NumberFormat('en-US', { minimumFractionDigits, maximumFractionDigits }).format(number || 0);

export const countDecimalPlaces = (num: number) => {
  if (!num) return 0;
  const numStr = num.toString();
  const decimalIndex = numStr.indexOf('.');
  if (decimalIndex === -1) {
    return 0;
  }
  return numStr.length - decimalIndex - 1;
};

export const handleViewStatusItem = (status: string): string => {
  switch (status) {
    case STATUS_ITEM_MANAGEMENT.NOT_DELIVERED:
    case STATUS_ITEM_MANAGEMENT.NOT_SHIPPED:
      return 'バイヤー発送前';
    case STATUS_ITEM_MANAGEMENT.IN_TRANSIT:
      return '返送中';
    case STATUS_ITEM_MANAGEMENT.DELIVERED:
    case STATUS_ITEM_MANAGEMENT.WAITING_STORAGE:
    case STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO:
      return '到着済';
    case STATUS_ITEM_MANAGEMENT.IN_STORAGE:
      return '保管中';
    case STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING:
    case STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING_DONE:
      return '発送手配中';
    case STATUS_ITEM_MANAGEMENT.SHIPPED_BACK:
      // TODO: phase 2 shipback cần check lại
      return '発送';
    case STATUS_ITEM_MANAGEMENT.DISPOSED:
      return '廃棄済';
    case STATUS_ITEM_MANAGEMENT.CANCELED:
      return 'キャンセル済';
    case STATUS_ITEM_MANAGEMENT.NEW_DISPOSAL_REQUEST:
      return '廃棄依頼';
    case STATUS_ITEM_MANAGEMENT.SHIPPED_TO_BUYER:
      return 'バイヤーへ発送済';
    case STATUS_ITEM_MANAGEMENT.PRICE_INPUTED:
      return '廃棄処理待ち';
    default:
      return '';
  }
};

export const fetchFileContent = async (url) => {
  try {
    const response = await axios.get(url, {
      responseType: 'arraybuffer', // Important for handling binary data
    });
    return {
      filename: url.substring(url.lastIndexOf('/') + 1), // Extract filename from URL
      content: response.data,
    };
  } catch (error) {
    console.error('Error fetching file:', error);
    return null;
  }
};

export const getLinkOrderEbayNavigate = (orderId: string) => `https://www.ebay.com/mesh/ord/details?orderid=${orderId}`;

export const sentToSlack = async (msg: string) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/sent-to-slack`,
    {
      message: msg,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const resizeImageItemEbay = (urlImage: string, size: number = 150) => {
  if (!urlImage) return urlImage;
  const split = urlImage.split('/');
  return (split[split.length - 1] = `s-l${size}.jpg`), split.join('/');
};

export const convertFullwidthToHalfwidth = (str: string) => {
  return str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 65248));
};

export const calculateVolumnWeight = (height: number, width: number, length: number) => {
  return (height * width * length) / 5000;
};
