export const PenSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
      <path
        d="M9.99984 4.00045L11.9998 6.00045M8.6665 13.3338H13.9998M3.33317 10.6671L2.6665 13.3338L5.33317 12.6671L13.0572 4.94312C13.3071 4.69308 13.4476 4.354 13.4476 4.00045C13.4476 3.6469 13.3071 3.30782 13.0572 3.05778L12.9425 2.94312C12.6925 2.69315 12.3534 2.55273 11.9998 2.55273C11.6463 2.55273 11.3072 2.69315 11.0572 2.94312L3.33317 10.6671Z"
        stroke="#514B4D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
