export const SvgIconChecked = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 13.3332L16.344 16.5918C16.6131 16.7937 16.9486 16.8863 17.2832 16.8512C17.6177 16.8161 17.9266 16.6558 18.148 16.4025L26.6667 6.6665"
      stroke="#1C86ED"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M28 15.9998C28 18.5072 27.2147 20.9516 25.7542 22.9897C24.2937 25.0278 22.2315 26.5572 19.8572 27.3632C17.4829 28.1691 14.9158 28.2111 12.5164 27.4832C10.117 26.7553 8.0059 25.2942 6.47956 23.3049C4.95323 21.3156 4.08836 18.8982 4.00641 16.3922C3.92447 13.8861 4.62958 11.4174 6.0227 9.33266C7.41582 7.24793 9.42697 5.65195 11.7737 4.76887C14.1204 3.8858 16.6848 3.75999 19.1067 4.40912"
      stroke="#1C86ED"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default SvgIconChecked;
