import AppLocale from '../../lngProvider/ja-JP';
import { sentToSlack } from '../../util/function-util';
import NotificationError from '../notifications/NotificationError';

export function CustomHandleError(error: any, fullError?: any) {
  const locale = localStorage.getItem('locale') || 'ja';
  const { messages } = AppLocale[`${locale}`];

  if (error?.statusCode === 500 || error?.statusCode === 501) {
    NotificationError({
      content: messages[`error.500`],
    });
  } else if (error?.statusCode === 403) {
    NotificationError({
      content: messages[`error.403`],
    });
  } else if (error?.statusCode === 401) {
    NotificationError({
      content: messages[`error.401`],
    });
  } else if (error?.statusCode === 404) {
    NotificationError({
      content: messages[`error.404`],
    });
  } else if (error?.statusCode === 406) {
    NotificationError({
      content: messages[`error.406`],
    });
  } else {
    const errorMessage = (error.message || '').replace(/\s/g, '_').toUpperCase();
    const errorContent = messages[`error.${errorMessage}`];

    if (errorMessage && errorContent) {
      NotificationError({
        content: errorContent,
      });
    } else {
      NotificationError({
        content: messages[`error.500`],
      });
      if (process.env.REACT_APP_ENVIRONMENT == 'staging' || process.env.REACT_APP_ENVIRONMENT == 'production') {
        const msg = `
          API RETURN ERROR IN ${process.env.REACT_APP_ENVIRONMENT}
          method: ${fullError?.response?.config?.method} \n
          url: ${fullError?.response?.config?.url} \n
          token: ${fullError?.response?.config?.headers?.Authorization} \n
          data: ${fullError?.response?.config?.data} \n
          message: ${error.message}
        `;
        sentToSlack(msg);
      }
    }
  }
}
