import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SuspenseWrapper } from '../../components/loading/SuspenseWrap';
import AuthRoute from '../../components/router/auth.router';
import { ADMIN_ROUTE_NAME, ADMIN_ROUTE_PATH } from '../../constants/router';
import ItemMangement from './itemMangement';
import ItemDetail from './itemMangement/itemDetail';
import ListNotifyManagement from './notify';
import RequestMangement from './requestManagement';

const Admin = React.lazy(() => import('./index'));
// const ListCustomer = React.lazy(() => import('./customer/ListCustomer'));
// const ListRole = React.lazy(() => import('./role/ListRole'));
// const CreateRole = React.lazy(() => import('./role/CreateEditRole'));
const SignInAdmin = React.lazy(() => import('./auth/SignInAdmin'));
const ListStaff = React.lazy(() => import('./staff/listStaff'));
const ActionStaff = React.lazy(() => import('./staff/actionStaff'));
const ListCustomerOrder = React.lazy(() => import('./customerOrder'));
const LisPaymentSetting = React.lazy(() => import('./paymentSetting/listPaymentSetting'));
const ActionVersion = React.lazy(() => import('./paymentSetting/actionPaymentSetting'));
const ListChatSuport = React.lazy(() => import('./chatSupport/listChat'));
const Storage = React.lazy(() => import('./storage'));
const WarehouseCheck = React.lazy(() => import('./warehouseCheck'));
const Profile = React.lazy(() => import('./profile/Profile'));
const Setting = React.lazy(() => import('./setting'));
const PageNotFound = React.lazy(() => import('./pageNotFound'));
const ListCouponManagement = React.lazy(() => import('./coupon/list'));
const ListAnalysisManagement = React.lazy(() => import('./analysis'));
const ListRackManagement = React.lazy(() => import('./rack/listRack'));
const ShipmentManagement = React.lazy(() => import('./shipmentManagement'));
const UserManagement = React.lazy(() => import('./userManagement'));
const UserDetail = React.lazy(() => import('./userManagement/userDetail'));
const SelectPlan = React.lazy(() => import('./planSetting/selectPlan'));
const PlanDetail = React.lazy(() => import('./planSetting/planDetail'));
const Revenue = React.lazy(() => import('./revenue'));
const RevenueDetail = React.lazy(() => import('./revenue/revenueDetail/detail'));
const Campaign = React.lazy(() => import('./campaign'));
export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path={ADMIN_ROUTE_PATH.SIGNIN} element={<SuspenseWrapper component={<SignInAdmin />} />} />
      <Route element={<AuthRoute />}>
        <Route path={ADMIN_ROUTE_NAME.DASHBOARD} element={<Admin />}>
          <Route path="" />
          <Route path={ADMIN_ROUTE_NAME.PROFILE} element={<SuspenseWrapper component={<Profile />} />} />
          <Route path={ADMIN_ROUTE_NAME.SETTING} element={<SuspenseWrapper component={<Setting />} />} />

          {/* staff */}
          <Route path={ADMIN_ROUTE_NAME.STAFF}>
            <Route path="" element={<SuspenseWrapper component={<ListStaff />} />} />
            <Route path={ADMIN_ROUTE_NAME.CREATE} element={<SuspenseWrapper component={<ActionStaff />} />} />
            <Route path={ADMIN_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<ActionStaff />} />} />
          </Route>

          {/* customer order */}
          <Route path={ADMIN_ROUTE_NAME.CUSTOMER_ORDER}>
            <Route path="" element={<SuspenseWrapper component={<ListCustomerOrder />} />} />
          </Route>

          {/* payment-setting */}
          <Route path={ADMIN_ROUTE_NAME.PAYMENT_SETTING}>
            <Route path="" element={<SuspenseWrapper component={<LisPaymentSetting />} />} />
            <Route path={ADMIN_ROUTE_NAME.CREATE} element={<SuspenseWrapper component={<ActionVersion />} />} />
            <Route path={ADMIN_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<ActionVersion />} />} />
            <Route path={ADMIN_ROUTE_PATH.PLAN_SETTING} element={<SuspenseWrapper component={<SelectPlan />} />} />
            <Route
              path={ADMIN_ROUTE_PATH.CREATE_PREVIEW_PLAN}
              element={<SuspenseWrapper component={<SelectPlan />} />}
            />
            <Route path={ADMIN_ROUTE_PATH.SELECT_PLAN} element={<SuspenseWrapper component={<PlanDetail />} />} />
            <Route
              path={ADMIN_ROUTE_PATH.CREATE_SELECT_PLAN}
              element={<SuspenseWrapper component={<PlanDetail />} />}
            />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.CHAT_SUPPORT}>
            <Route path="" element={<SuspenseWrapper component={<ListChatSuport />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.NOTIFY_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ListNotifyManagement />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.STORAGE}>
            <Route path="" element={<SuspenseWrapper component={<Storage />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.SHIPMENT_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ShipmentManagement />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.CUSTOMER_INFORMATION}>
            <Route path="" element={<SuspenseWrapper component={<UserManagement />} />} />
            <Route path="detail" element={<SuspenseWrapper component={<UserDetail />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.WAREHOUSE_CHECK}>
            <Route path="" element={<SuspenseWrapper component={<WarehouseCheck />} />} />
          </Route>
          <Route path={ADMIN_ROUTE_NAME.NOTIFY_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ListNotifyManagement />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.ITEM_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ItemMangement />} />} />
            <Route
              path={ADMIN_ROUTE_NAME.ITEM_MANAGEMENT_DETAIL}
              element={<SuspenseWrapper component={<ItemDetail />} />}
            />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.REQUEST_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<RequestMangement />} />} />
            <Route
              path={ADMIN_ROUTE_NAME.REQUEST_MANAGEMENT_DETAIL}
              element={<SuspenseWrapper component={<ItemDetail />} />}
            />
          </Route>
          <Route path={ADMIN_ROUTE_NAME.COUPON_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ListCouponManagement />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.RACK_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ListRackManagement />} />} />
          </Route>
          <Route path={ADMIN_ROUTE_NAME.REVENUE}>
            <Route path="" element={<SuspenseWrapper component={<Revenue />} />} />
            <Route path="detail" element={<SuspenseWrapper component={<RevenueDetail />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.ANALYSIS_MANAGEMENT}>
            <Route path="" element={<SuspenseWrapper component={<ListAnalysisManagement />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.CUSTOMER_INFORMATION}>
            <Route path="" element={<SuspenseWrapper component={<ListAnalysisManagement />} />} />
          </Route>

          <Route path={ADMIN_ROUTE_NAME.CAMPAIGN}>
            <Route path="" element={<SuspenseWrapper component={<Campaign />} />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />

          {/* <Route path="admin/" element={<ListCustomer />} />
      <Route path={ADMIN_ROUTE_NAME.DASHBOARD} element={<Admin />}>
        <Route path={ADMIN_ROUTE_NAME.STAFF}>
          <Route path="" element={<SuspenseWrapper component={<ListStaff />} />} />
          <Route path={ADMIN_ROUTE_NAME.CREATE} element={<SuspenseWrapper component={<ActionStaff />} />} />
          <Route path={ADMIN_ROUTE_NAME.DETAIL} element={<SuspenseWrapper component={<ActionStaff />} />} />
        </Route>
        {/* <Route path="admin/" element={<ListCustomer />} />
        <Route path="roles">
          <Route path="" element={<SuspenseWrapper component={<ListRole />} />} />
          <Route path="create" element={<SuspenseWrapper component={<CreateRole />} />} />
          <Route path="detail/:id" element={<SuspenseWrapper component={<CreateRole />} />} />
        </Route> */}
        </Route>
      </Route>
    </Routes>
  );
};
