export default function SvgArrowDown() {
  return (
    <svg width="10" height="22" viewBox="0 0 10 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 17L5 21M5 21L9 17M5 21V1"
        stroke="#514B4D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
