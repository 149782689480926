export default function SvgAddTask() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_33205_220479)">
        <path
          d="M4.66667 0.666992H11.3333V2.00033H14V8.00033H12.6667V3.33366H11.3333V4.66699H4.66667V3.33366H3.33333V14.0003H8V15.3337H2V2.00033H4.66667V0.666992ZM6 3.33366H10V2.00033H6V3.33366ZM13.3333 9.33366V12.0003H16V13.3337H13.3333V16.0003H12V13.3337H9.33333V12.0003H12V9.33366H13.3333Z"
          fill="#FFB021"
        />
      </g>
      <defs>
        <clipPath id="clip0_33205_220479">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
