import { DatePicker, DatePickerProps } from 'antd';
import './datePikcerCustom.scss';
import { SvgDatePickerIcon } from '../@svg/SvgDatePickerIcon';
import React from 'react';
import updateLocale from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';
import jp from 'antd/es/date-picker/locale/ja_JP';
import en from 'antd/es/date-picker/locale/en_US';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const jpLocale: typeof jp = {
  ...jp,
  lang: {
    ...jp.lang,
    yearFormat: 'YYYY年',
    monthFormat: 'M月',
    shortMonths: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    shortWeekDays: ['日', '月', '火', '水', '木', '金', '土'],
  },
};

const enLocale: typeof en = {
  ...en,
  lang: {
    ...en.lang,
    yearFormat: 'YYYY',
    monthFormat: 'MM',
    shortWeekDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  },
};

interface IDatepickerCustom {
  datePickerProp?: DatePickerProps;
  minDate?: dayjs.Dayjs;
}

const DatepickerCustom = ({ ...props }: IDatepickerCustom) => {
  const { datePickerProp, minDate } = props;
  const locale = useSelector((state: RootState) => state.setting).locale.locale;
  return (
    <DatePicker
      disabled={datePickerProp?.disabled}
      superNextIcon={false}
      superPrevIcon={false}
      showNow={false}
      placeholder={''}
      locale={locale === 'ja' ? jpLocale : enLocale}
      {...datePickerProp}
      className={`custom__date-picker ${datePickerProp?.className ?? ''}`}
      panelRender={(panel) => {
        return <div className="custom__date-picker-panel">{panel}</div>;
      }}
      suffixIcon={<SvgDatePickerIcon />}
      minDate={minDate}
    />
  );
};

export default DatepickerCustom;
