import { Input, InputProps } from 'antd';
import { useIntl } from 'react-intl';
import './index.scss';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { convertFullwidthToHalfwidth } from '../../util/function-util';

interface CustomInputProps extends InputProps {
  placeholder?: string;
  className?: string;
  isPassword?: boolean;
  width?: number;
  height?: number;
}

const CustomInput = (props: CustomInputProps) => {
  const intl = useIntl();
  const { placeholder, className, isPassword, width, height, ...restProps } = props;
  const [inputValue, setInputValue] = useState<string | undefined>(restProps.value as string);

  const inputStyle = { height, width };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const convertedValue = convertFullwidthToHalfwidth(e.target.value);
    setInputValue(convertedValue);
    if (restProps.onChange) {
      restProps.onChange({ ...e, target: { ...e.target, value: convertedValue } });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const convertedValue = convertFullwidthToHalfwidth(e.target.value);

    if (restProps.onBlur) {
      restProps.onBlur({ ...e, target: { ...e.target, value: convertedValue } });
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('Text');
    const convertedValue = convertFullwidthToHalfwidth(pastedText);
    setInputValue(convertedValue);

    if (restProps.onChange) {
      restProps.onChange({
        ...e,
        target: { ...e.target, value: convertedValue } as EventTarget & HTMLInputElement,
      });
    }
  };

  return !isPassword ? (
    <Input
      style={inputStyle}
      maxLength={255}
      placeholder={placeholder || undefined}
      className={`ant-custom-input ${className}`}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onPaste={handlePaste}
      {...restProps}
    />
  ) : (
    <Input.Password
      style={inputStyle}
      maxLength={255}
      placeholder={placeholder || undefined}
      className={`ant-custom-input ${className}`}
      autoComplete="new-password"
      iconRender={(visible) =>
        visible ? <EyeInvisibleOutlined style={{ fontSize: '24px' }} /> : <EyeOutlined style={{ fontSize: '24px' }} />
      }
      value={inputValue}
      onChange={handleChange}
      {...restProps}
    />
  );
};

CustomInput.TextArea = Input.TextArea;
export default CustomInput;
