export default function SvgFilterSort() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 5L4.5 1M4.5 1L1 5M4.5 1V21"
        stroke="#514B4D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 17L12 21M12 21L16 17M12 21V1"
        stroke="#514B4D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
