import { Select } from 'antd';
import { SelectProps } from 'antd/lib';
import { SvgArrowDownSelect } from '../../@svg/SvgArrowDownSelect';
import SvgIconCheck from '../../@svg/SvgCoppy';
import ButtonAgree from '../../buttonCustom/ButtonAgree';
import './index.scss';
type TSelectOptionChatProps = {
  options: SelectProps['options'];
  value: SelectProps['value'];
  onClickButton: SelectProps['onClick'];
  onChangeSelect: SelectProps['onChange'];
  className?: SelectProps['className'];
  buttonClassName?: SelectProps['className'];
  disabled?: SelectProps['disabled'];
};
export default function SelectOptionChat({
  options,
  value,
  onClickButton,
  onChangeSelect,
  className,
  buttonClassName,
  disabled,
}: TSelectOptionChatProps) {
  return (
    <div className="list-option-chat">
      <Select
        className={`list-option-chat-select ${className || ''}`}
        popupClassName="list-option-chat-select__value"
        menuItemSelectedIcon={<SvgIconCheck />}
        options={options}
        suffixIcon={<SvgArrowDownSelect />}
        value={value}
        labelRender={({ label }) => label}
        onChange={onChangeSelect}
      />
      <ButtonAgree
        value="更新"
        onClick={onClickButton}
        disabled={!value || disabled}
        subClass={`btn-agree-selected ${buttonClassName || ''}`}
      />
    </div>
  );
}
