import {
  CloseOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Divider, Image } from 'antd';
import dayjs from 'dayjs';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { itemInquiryMessageApi } from '../../../../../apis';
import { ItemInquiryMessages, ItemInternalMessages, ItemMessageGroup } from '../../../../../apis/client-axios';
import { RootState } from '../../../../../store';
import { QUERY_KEY, TIME_FORMAT } from '../../../../../util/constants';
import { Helper } from '../../../../../util/helper';
import { useIntl } from 'react-intl';
import { ChatItemContext } from '..';

export interface InquiryTabProps {
  listMessage: ItemInquiryMessages[];
  setListMesage: React.Dispatch<React.SetStateAction<ItemInquiryMessages[] | [] | ItemInternalMessages[]>>;
  totalReponse: number;
  setTotalResponse: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  size: number;
  setSkipLoad: React.Dispatch<React.SetStateAction<boolean>>;
  skipLoad: boolean;
  setFileUploads: React.Dispatch<React.SetStateAction<{ privew: string; source: File }[] | undefined>>;
  fileUploads: { privew: string; source: File }[] | undefined;
  scrollToEnd: boolean;
  groupSelect: ItemMessageGroup | undefined;
}
export const InquiryTab = () => {
  const {
    listMessage,
    setListMesage,
    totalReponse,
    setTotalResponse,
    page,
    setPage,
    size,
    skipLoad,
    setSkipLoad,
    fileUploads,
    setFileUploads,
    scrollToEnd,
    groupSelect,
  } = useContext(ChatItemContext);
  const intl = useIntl();
  const dummy = useRef(null);
  const { authUser } = useSelector((state: RootState) => state.auth);
  const [visibleSender, setVisibleSender] = useState(false);
  const [previewSender, setPreviewSender] = useState<{ source: string; index: number } | undefined>(undefined);
  const [visibleReceiver, setVisibleReceiver] = useState(false);
  const [previewReceiver, setPreviewReceiver] = useState<{ source: string; index: number } | undefined>(undefined);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.LIST_GROUP_ITEM_MESSAGE_INQUIRY, page],
    queryFn: () =>
      itemInquiryMessageApi.itemInquiryControllerGetAll(groupSelect?.id, page, size).then((res: any) => {
        let response: any = res;
        if (!!(res?.data as any)?.content?.length) {
          response.data.content = (res?.data as any)?.content.reverse();
        }
        return response;
      }),
    enabled: !!groupSelect?.id && groupSelect?.type == 0 && authUser?.role != 'operation',
    staleTime: 1000,
  });

  useEffect(() => {
    if (data?.data?.content) {
      if (page == 1) {
        setListMesage(data?.data?.content as ItemInquiryMessages[]);
      } else {
        if (!skipLoad) {
          const messages = [...(data?.data?.content as ItemInquiryMessages[]), ...listMessage];
          setListMesage(messages);
          setSkipLoad(true);
        }
      }
      setTotalResponse(data?.data?.content?.length || 0);
    }
  }, [data]);

  useEffect(() => {
    if (dummy?.current && !isLoading && (page === 1 || scrollToEnd)) {
      document.getElementById('scrollMessage')?.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'end' });
    }
  }, [listMessage, dummy?.current, scrollToEnd]);
  return (
    <>
      {listMessage?.map((item: ItemInquiryMessages) => {
        return (
          <Fragment key={item?.id}>
            {!item?.isClosedInquiryMessage ? (
              +item?.sendSide === 0 ? (
                <div key={item?.id} className="item-detail_message_content_other">
                  <div className="item-detail_message_content_other_avatar">
                    {/* <img src={Helper.getSourceFile(authUser?.avatar?.source)} alt="alt.." /> */}
                    <Avatar size={32} src={Helper.getSourceFile(item?.sendUser?.avatar?.source)}></Avatar>
                  </div>
                  <div className="item-detail_message_content_other_text">
                    {!!item?.itemMessageImages?.length && (
                      <div>
                        {/* <Avatar
                          size={32}
                          src={Helper?.getSourceFile(
                            item.sendUser?.avatar?.source || item.sendStaff?.avatar?.source,
                          )}
                        /> */}
                        <div className="d-flex flex-column">
                          <span className="item-detail_message-file">
                            <Image.PreviewGroup
                              preview={
                                visibleReceiver
                                  ? {
                                      rootClassName: `image-preview__container ${!!item?.itemMessageImages?.length && item?.itemMessageImages?.length < 2 ? 'image-preview__container-limit' : ''} `,
                                      onChange: (current, prev) => {
                                        setPreviewReceiver({
                                          source: item?.itemMessageImages[current]?.source,
                                          index: current,
                                        });
                                      },
                                      src: Helper.getSourceFile(previewReceiver?.source),
                                      toolbarRender: (
                                        _,
                                        {
                                          transform: { scale },
                                          actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onClose },
                                          current,
                                        },
                                      ) => (
                                        <div className="ant-image-preview-operations">
                                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                          </div>
                                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                            <RotateRightOutlined onClick={onRotateRight} />
                                          </div>
                                          <div
                                            className={`ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut ${scale === 1 && 'ant-image-preview-operations-operation-disabled'}`}
                                          >
                                            <ZoomOutOutlined disabled={true} onClick={onZoomOut} />
                                          </div>
                                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                          </div>
                                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                            <Divider type="vertical" className="bg-white" />
                                          </div>
                                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                            <CloseOutlined
                                              disabled={scale === 50}
                                              onClick={() => {
                                                onClose();
                                                // setVisibleReceiver(false)
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ),
                                    }
                                  : false
                              }
                            >
                              {item.itemMessageImages?.map((item, index) => {
                                return (
                                  <Image
                                    key={item?.id + index}
                                    onClick={() => {
                                      setVisibleReceiver(true);
                                      setPreviewReceiver({
                                        source: item?.source,
                                        index: +index + 1,
                                      });
                                    }}
                                    width={98}
                                    height={98}
                                    className="rounded"
                                    src={Helper.getSourceFile(item?.source)}
                                  />
                                );
                              })}
                            </Image.PreviewGroup>
                          </span>
                          {/* <div className="d-flex flex-column">
                            <span className="color-sub-title font-weight-600 font-size-12 m-t-2">
                              {item.sendUser?.name || item.sendStaff?.loginId || item.sendUser?.emailAddress}
                            </span>
                            <span className="color-sub-cancel font-size-10 m-t-2">
                              {dayjs(item.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                            </span>
                          </div> */}
                        </div>
                      </div>
                    )}
                    <p className="item-detail_message_content_other_text_content m-t-2">{item?.message}</p>
                    <p className="item-detail_message_content_other_text_name m-t-2">
                      {item?.sendUser?.name || item?.sendUser?.emailAddress}
                    </p>
                    <p className="item-detail_message_content_other_text_time">
                      {' '}
                      {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {item?.sendStaffId === authUser?.id ? (
                    <div className="item-detail_message_content_me">
                      <div className="item-detail_message_content_me_avatar">
                        {/* <img src={Helper.getSourceFile(authUser?.avatar?.source)} alt="alt.." /> */}
                        <Avatar size={32} src={Helper.getSourceFile(authUser?.avatar?.source)}></Avatar>
                      </div>
                      <div className="item-detail_message_content_me_text">
                        {!!item?.itemMessageImages?.length && (
                          <div>
                            <div className="d-flex flex-column align-items-end">
                              <span className="item-detail_message-file">
                                <Image.PreviewGroup
                                  preview={
                                    visibleSender
                                      ? {
                                          rootClassName: `image-preview__container ${!!item?.itemMessageImages?.length && item?.itemMessageImages?.length < 2 ? 'image-preview__container-limit' : ''} `,
                                          onChange: (current, prev) => {
                                            setPreviewSender({
                                              source: item?.itemMessageImages[current]?.source,
                                              index: current,
                                            });
                                          },
                                          src: Helper.getSourceFile(previewSender?.source),
                                          toolbarRender: (
                                            _,
                                            {
                                              transform: { scale },
                                              actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onClose },
                                            },
                                          ) => (
                                            <div className="ant-image-preview-operations">
                                              <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                                <RotateLeftOutlined onClick={onRotateLeft} />
                                              </div>
                                              <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                                <RotateRightOutlined onClick={onRotateRight} />
                                              </div>
                                              <div
                                                className={`ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut ${scale === 1 && 'ant-image-preview-operations-operation-disabled'}`}
                                              >
                                                <ZoomOutOutlined disabled={true} onClick={onZoomOut} />
                                              </div>
                                              <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                              </div>
                                              <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                                                <Divider type="vertical" className="bg-white" />
                                              </div>
                                              <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut-close">
                                                <CloseOutlined
                                                  disabled={scale === 50}
                                                  onClick={() => {
                                                    // setVisibleSender(false);
                                                    onClose();
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ),
                                        }
                                      : false
                                  }
                                >
                                  {item?.itemMessageImages?.map((item, index) => {
                                    return (
                                      <Image
                                        key={item?.id + index}
                                        onClick={() => {
                                          setVisibleSender(true);
                                          setPreviewSender({
                                            source: item?.source,
                                            index: +index + 1,
                                          });
                                        }}
                                        width={98}
                                        height={98}
                                        className="rounded"
                                        src={Helper.getSourceFile(item?.source)}
                                      />
                                    );
                                  })}
                                </Image.PreviewGroup>
                              </span>
                              {/* <span className="color-sub-title font-weight-600 font-size-12 m-t-2">
                                {item?.sendStaff?.name || item?.sendStaff?.loginId}
                              </span>
                              <span className="color-sub-cancel font-size-10 m-t-2">
                                {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                              </span> */}
                            </div>
                          </div>
                        )}
                        <div className="d-flex justify-content-end">
                          <p className="item-detail_message_content_me_text_content m-t-2">{item?.message}</p>
                        </div>
                        <p className="item-detail_message_content_me_text_name m-t-2">
                          {item?.sendStaff?.name || item?.sendStaff?.loginId}
                        </p>
                        <p className="item-detail_message_content_me_text_time">
                          {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="item-detail_message_content_me">
                      <div className="item-detail_message_content_me_avatar">
                        {/* <img src="/assets/icons/chatsupport.png" alt="" /> */}
                        <Avatar size={32} src={Helper?.getSourceFile(item.sendStaff?.avatar?.source)} />
                      </div>
                      <div className="item-detail_message_content_me_text">
                        <p className="item-detail_message_content_me_text_content-other m-t-2">{item?.message}</p>
                        <p className="item-detail_message_content_me_text_name m-t-2">
                          {item?.sendStaff?.name || item?.sendStaff?.loginId}
                        </p>
                        <p className="item-detail_message_content_me_text_time">
                          {dayjs(item?.createdAt).format(TIME_FORMAT.MESSAGE_TIME)}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )
            ) : (
              <div className="item-detail_message_content_seen">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M4.125 13.125L9.375 18.375L19.875 7.125"
                      stroke="#514B4D"
                      strokeWidth="1.875"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>{intl.formatMessage({ id: 'chat-item.inquiry.closed' })}</span>
                </p>
              </div>
            )}
          </Fragment>
        );
      })}
      <div className="p-t-12" ref={dummy} id="scrollMessage" style={{ height: '1px' }}></div>
    </>
  );
};
