export const SvgIconDowload = () => (
  <span>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Download">
        <path
          id="Vector"
          d="M14 17.5L9.625 13.125L10.85 11.8562L13.125 14.1312V7H14.875V14.1312L17.15 11.8562L18.375 13.125L14 17.5ZM8.75 21C8.26875 21 7.85692 20.8288 7.5145 20.4864C7.17208 20.144 7.00058 19.7318 7 19.25V16.625H8.75V19.25H19.25V16.625H21V19.25C21 19.7312 20.8288 20.1434 20.4864 20.4864C20.144 20.8294 19.7318 21.0006 19.25 21H8.75Z"
          fill="#1C86ED"
        />
      </g>
    </svg>
  </span>
);

export default SvgIconDowload;
